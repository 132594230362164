.projects-container {
  position: relative;
  height: 100%;
  width: 100vw;
  background-color: #121212;
  /* padding: 80px 20px 200px 20px; */
}

.projects-title {
  margin-left: -50px;
}

.background-skewd {
  width: 100%;
  position: absolute;
  top: 25%;
  left: 0;
  height: 400px;
  transform: skewY(20deg);
  background-color: #737373a9;
  z-index: 0;
}

.projects-wrap {
  position: relative;
  z-index: 1;
  padding-top: 100px;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.section1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
  padding: 120px 20px 0px 20px;
}

.section2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
  padding: 0px 20px 80px 20px;
}

.item-wrap {
  width: 100%;
  height: fit-content;
}

.item-wrap2 {
  width: 100%;
  height: fit-content;
}

@media screen and (min-width: 768px) {
  .projects-container {
    margin-bottom: 100px;
  }
  .projects-title {
    margin-left: -100px;
  }
}

@media screen and (min-width: 1024px) {
  .section1 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 120px 40px 0px 40px;
  }

  .section2 {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    padding: 0px 40px 80px 40px;
  }
}

.project-container {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 1px 7px 12px 7px rgba(0, 0, 0, 0.78);
}
.project-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

@media screen and (min-width: 1024px) {
  .project-container {
  }
  .project-image {
    width: 100%;
    height: 500px;
  }
}

@media screen and (min-width: 1440px) {
  .background-skewd {
    top: 40%;
  }
}

.title-wrap {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #2a2a2a;
  width: 100%;
  padding: 20px 12px 20px 30px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.project-title {
  text-align: left;
}

.btn-flip {
  justify-content: flex-start;
  opacity: 1;
  outline: 0;
  color: #fff;
  line-height: 40px;
  position: relative;
  text-align: left;
  display: inline-block;
  text-decoration: none;
}

.btn-flip:hover::after {
  opacity: 1;
  transform: translateY(0) rotateX(0);
}

.btn-flip:hover::before {
  opacity: 0;
  transform: translateY(50%) rotateX(90deg);
}

.btn-flip::after {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  color: #0ef6cc;
  display: block;
  transition: 0.5s;
  position: absolute;
  content: attr(data-back);
  transform: translateY(-50%) rotateX(90deg);
}

.btn-flip::before {
  top: 0;
  left: 0;
  opacity: 1;
  color: #adadaf;
  display: block;
  padding: 0 0px;
  line-height: 27px;
  transition: 0.5s;
  /* position: relative; */

  /* background: #323237; */
  content: attr(data-front);
  transform: translateY(0) rotateX(0);
}

.project-hover:hover .btn-flip::after {
  opacity: 1;
  transform: translateY(0) rotateX(0);
}

.project-hover:hover .btn-flip::before {
  opacity: 0;
  transform: translateY(50%) rotateX(90deg);
}
.project-hover:hover .project-image {
  transform: scale(1.1);
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}
.modal-wrapper {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 1px 7px 12px 7px rgba(0, 0, 0, 0.78);
  border-radius: 20px;
  position: relative;
  padding: 20px 40px;
  gap: 30px;
  border: none;
}

.modal-wrapper:focus {
  border: none;
}

.videoClass {
  width: 100%;
  height: auto;
  border-radius: 12px;
}

.video-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  min-width: 300px;
  max-height: 200px;
}

@media screen and (min-width: 768px) {
  .video-wrapper {
    min-width: 300px;
    max-height: 350px;
  }
}

@media screen and (min-width: 1024px) {
  .video-wrapper {
    min-width: 400px;
  }
}

@media screen and (min-width: 1440px) {
  .video-wrapper {
    min-width: 560px;
  }
}

.text-section-video {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
}
.title-proj {
  font-size: 18px;
}
.description {
  width: 100%;
  color: rgb(191, 188, 188);
  font-size: 16px;
  line-height: 25px;
  text-align: left;
  align-self: center;
}

.react-icon {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-40px, 25px);
  cursor: pointer;
}

.bottom-right {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(-40px, -25px);
  cursor: pointer;
}

.arrow-text {
  font-size: 14px;
}

.bottom-left {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(40px, -25px);
  cursor: pointer;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 0px;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 30px;
  width: 100%;
}

@media screen and (min-width: 550px) {
  .videoClass {
    width: 80%;
  }
}

@media screen and (min-width: 768px) {
  .videoClass {
    width: 70%;
  }
}
@media screen and (min-width: 1024px) {
}
@media screen and (min-width: 1440px) {
  .modal-wrapper {
    flex-direction: row;
    gap: 12px;
    width: 80%;
    height: 80%;
    /* width: 670px; */
  }
  .videoClass {
    width: 65%;
    height: auto;
  }
  .buttons {
    margin-top: 30px;
  }
}

.blob-btn {
  z-index: 1;
  position: relative;
  padding: 15px 30px;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  color: #4f58fdee;
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
  outline: none;
  border: none;
  transition: color 0.5s;
  cursor: pointer;
  border-radius: 10px;
}

.blob-btn:before {
  content: "";
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background-image: linear-gradient(1deg, #4f58fd, #149bf3 99%); */
  border: 2px solid #4f58fdee;
  border-radius: 10px;
}

.blob-btn:after {
  content: "";
  z-index: -2;
  position: absolute;
  left: 3px;
  top: 3px;
  width: 100%;
  height: 100%;
  transition: all 0.3s 0.2s;
  border-radius: 10px;
}

.blob-btn:hover {
  color: rgb(211, 209, 209);
  border-radius: 10px;
}

.blob-btn:hover:after {
  transition: all 0.3s;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.blob-btn__inner {
  z-index: -1;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  /* background: #4f58fdee; */
}

.blob-btn__blobs {
  position: relative;
  display: block;
  height: 100%;
  filter: url("#goo");
}

.blob-btn__blob {
  position: absolute;
  top: 2px;
  width: 25%;
  height: 100%;
  background: #332cf2;
  border-radius: 100%;
  transform: translate3d(0, 150%, 0) scale(1.7);
  transition: transform 0.45s;
}

@supports (filter: url("#goo")) {
  .blob-btn__blob {
    transform: translate3d(0, 150%, 0) scale(1.4);
  }
}

.blob-btn__blob:nth-child(1) {
  left: 0%;
  transition-delay: 0s;
}

.blob-btn__blob:nth-child(2) {
  left: 25%;
  transition-delay: 0.08s;
}

.blob-btn__blob:nth-child(3) {
  left: 50%;
  transition-delay: 0.16s;
}

.blob-btn__blob:nth-child(4) {
  left: 75%;
  transition-delay: 0.24s;
}

.blob-btn:hover .blob-btn__blob {
  transform: translateZ(0) scale(1.7);
}

@supports (filter: url("#goo")) {
  .blob-btn:hover .blob-btn__blob {
    transform: translateZ(0) scale(1.4);
  }
}
