.skill-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 50vh;
  /* width: 100%; */

  @media screen and (min-width: 768px) {
    height: 60vh;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 5rem;
    height: 100vh;
  }
}

.skills-title {
  position: absolute;
  top: 0;
  letter-spacing: 20px;
  text-transform: uppercase;
  font-family: "Mona-Sans", sans-serif;
  font-weight: 700;
  color: #737373;

  font-size: 30px;

  @media screen and (min-width: 768px) {
    font-size: 40px;
  }
}

.skills-grid {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, 20%);
  width: 100%;
  overflow: hidden;
  z-index: 0;
  height: 20rem;
  @media screen and (min-width: 550px) {
    height: 30rem;
    transform: translate(0, 10%);
  }
  /* @media screen and (min-width: 769px) {
    transform: translate(0, 10%);
  } */
  @media screen and (min-width: 1024px) {
    height: 50rem;
  }
}

/* .skill-icon {
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;
  border: 1px solid #737373;
  padding: 12px;
} */
