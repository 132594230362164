.bottom-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #121212;
  padding: 0px 20px 200px 20px;
  background: linear-gradient(
    190deg,
    rgba(18, 18, 18, 1) 0%,
    rgba(18, 18, 18, 1) 28%,
    rgba(48, 48, 48, 1) 100%
  );
  /* padding: 0px -40px 240px -40px; */
}

.bottom-title {
  margin-top: 0px;
  letter-spacing: 20px;
  text-transform: uppercase;
  font-family: "Mona-Sans", sans-serif;
  font-weight: 700;
  color: #737373;
  font-size: 30px;
  font-size: 30px;
}

.contact-items {
  margin-top: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.contact-item {
  background-color: transparent;
  border-radius: 20px;
  box-shadow: 1px 7px 12px 7px rgba(0, 0, 0, 0.78);
}

.link-img {
  width: 70px;
  border-radius: 20px;
  transition: all 0.25s ease-in-out;
}

.link-img:hover {
  opacity: 0.5;
  cursor: pointer;
}

.finale {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 40px 20px;
  font-size: 12px;
}

@media screen and (min-width: 768px) {
  .bottom-title {
    margin-top: 40px;
    font-size: 40px;
  }
  .link-img {
    width: 100px;
  }
  .contact-items {
    gap: 70px;
  }

  .finale {
    padding: 40px 80px;
    font-size: 16px;
  }
}
@media screen and (min-width: 1024px) {
  .bottom-container {
    padding: 80px 80px 200px 80px;
  }
}

@media screen and (min-width: 1440px) {
}
