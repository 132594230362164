/* Fonts */
/* @font-face {
  font-family: Mogra;
  src: url("./assets/font/CentraNo2-Bold.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Mogra;
  src: url("./assets/font/CentraNo2-Book.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Mogra;
  src: url("./assets/font/CentraNo2-Medium.ttf");
  font-weight: 400;
} */

@font-face {
  font-family: Mogra;
  src: url("./assets/font/Mogra-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Lexend;
  src: url("./assets/font/Lexend-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Rubik;
  src: url("./assets/font/RubikMonoOne-Regular.ttf");
  font-weigh: 400;
}

@font-face {
  font-family: Mona-Sans;
  src: url("./assets/font/Mona-Sans.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Anton-Regular;
  src: url("./assets/font/Anton-Regular.ttf");
  font-weight: 400;
}

/* General Default */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  width: 12px;
}

html::-webkit-scrollbar-track {
  background-color: #121212;
}

html::-webkit-scrollbar-thumb {
  background-color: #4f58fdee;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  color: #fff !important;
  background-color: #121212;
  font-family: "Mona-Sans", sans-serif !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button {
  border: 0;
  background-color: transparent;
}

/************ Cursor ************/

/************ Background ************/

/* .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
} */

/************ App ************/
.App {
  background-color: #121212;
  /* scroll-snap-type: y mandatory;
  scroll-snap-align: center; */
  position: relative;
  scroll-behavior: smooth;
}

.section {
  /* scroll-snap-align: center; */
}

.main-title {
  position: absolute;
  top: 0;
  left: calc(100vw / 2 - 100px);
  margin-top: 80px;
  letter-spacing: 20px;
  text-transform: uppercase;
  font-family: "Mona-Sans", sans-serif;
  font-weight: 700;
  color: #737373;
  font-size: 30px;

  @media screen and (min-width: 768px) {
    font-size: 40px;
  }
}

/************ Spline Main Scene ************/
/* .spline-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
} */
