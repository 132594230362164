.exp-card-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: fit-content;
  scroll-snap-align: center;
  background-color: #292929;
  overflow: hidden;
  cursor: grap;
  transition: opacity 0.2s ease-in-out;
  opacity: 0.4;
}
.exp-card-wrapper:hover {
  opacity: 1;
}

.background-top {
  margin: -10px -30px;
  padding: 0;
  width: 100%;
  height: 100%;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-img {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
}

.details-wrap {
  margin: 80px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.position-name {
  font-size: 1.5rem;
  font-style: bold;
}

.company-name {
  font-size: 1rem;
  font-weight: 700;
  margin-top: 0.25rem;
  text-transform: uppercase;
  color: rgb(190, 189, 189);
}

.techs-used {
  display: flex;
  gap: 0.8rem;
  margin-top: 0.5rem;
}
.skill-img {
  width: 2rem;
  height: 2rem;
  border-radius: 20%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.date {
  text-transform: uppercase;
  padding: 10px 0;
  font-size: 0.8rem;
}
.summary {
  text-align: left;
  max-width: 100%;
  font-size: 0.8rem;
}

.links {
  display: flex;
  flex-wrap: wrap;
  text-decoration: underline;
  text-decoration-color: #737373;
  gap: 2px;
  font-size: 0.8rem;
}

@keyframes pulse {
  0% {
    transform: scale(1); /* Start with normal size */
  }
  50% {
    transform: scale(1.08); /* Scale up to 110% size */
  }
  100% {
    transform: scale(1); /* Return to normal size */
  }
}

.proj-link {
  color: #737373;
  padding-top: 3px;
}

.proj-link:hover {
  animation: pulse 1s ease;
  text-decoration: underline;
}

.exp-image {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  object-fit: cover;
  object-position: center;
  margin-bottom: 1rem;
}

.summary-list {
  list-style-type: disc;
  margin-left: 1.25rem;
  font-size: 1.125rem;
}

.list-point {
  margin-bottom: 1rem;
}

@media screen and (min-width: 450px) {
  .exp-card-wrapper {
    width: 412px;
  }

  .background-top {
    min-height: 440px;
  }
}

@media screen and (min-width: 769px) {
  .exp-card-wrapper {
    width: 500;
  }

  .background-top {
    min-height: 500px;
  }
}

@media screen and (min-width: 1024px) {
  .exp-card-wrapper {
    width: 650px;
  }

  .background-top {
    min-height: 620px;
  }

  .card-img {
    width: 8rem;
    height: 8rem;
  }

  .details-wrap {
    margin: 70px 30px 0px 30px;
    padding: 0px;
  }
  .position-name {
    font-size: 2.25rem;
  }
  .company-name {
    font-size: 1.5rem;
  }
  .skill-img {
    width: 2.5rem;
    height: 2.5rem;
  }

  /* .date {

  } */
  .summary,
  .date {
    font-size: 1.2rem;
    line-height: 27px;
  }

  .links {
    gap: 8px;
    font-size: 1.2rem;
  }
}
/* 
========================
      BUTTON THREE
========================
*/
.btn-three {
  color: #fff;
  transition: all 0.5s;
  position: relative;
}
.btn-three::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  background-color: rgba(90, 8, 52, 0.1);
  transition: all 0.3s;
}
.btn-three:hover::before {
  opacity: 0;
  transform: scale(0.5, 0.5);
}
.btn-three::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transform: scale(1.2, 1.2);
}
.btn-three:hover::after {
  opacity: 1;
  transform: scale(1, 1);
}
