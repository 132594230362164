.exp-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  height: 100%;
  width: 100vw;
  gap: 20px;
  margin: 80px 0px;
  cursor: grab;

  @media screen and (min-width: 1025px) {
    margin: 80px 0px 180px;
    flex-direction: row;
    gap: 40px;
    justify-content: space-evenly;
  }
}

.experience-title {
  position: absolute;
  top: 0;
  left: calc(100vw / 2);
  margin-top: 80px;
  letter-spacing: 20px;
  text-transform: uppercase;
  font-family: "Mona-Sans", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #737373;
  margin-left: -10rem;
  @media screen and (min-width: 768px) {
    margin-left: -12rem;
    font-size: 40px;
  }
}

.experience-cards {
  margin-top: 6.5rem;
  display: flex;
  width: 100%;
  gap: 1.25rem;
  overflow-x: scroll;
  padding: 2.5rem;
  /* scroll-snap-type: x mandatory;
  scroll-behavior: smooth; */

  @media screen and (min-width: 1025px) {
    margin-top: 10.5rem;
  }
  @media screen and (min-width: 1440px) {
    margin-top: 15.5rem;
  }
}

#experienceCards::-webkit-scrollbar {
  width: 12px;
}

#experienceCards::-webkit-scrollbar-track {
  background-color: #121212;
  /* border: 1px solid #cacaca; */
}

#experienceCards::-webkit-scrollbar-thumb {
  /* border-radius: 8px; */
  background-color: #4f58fdee;
}
